<template>
  <div class="page-content">
    <div class="row">
      <Loading v-if="pgLoading"> </Loading>

      <div v-if="!pgLoading" class="col-8">
        <FormElements
          :label="$t('view.service_info')"
          :labelTitle="$t('view.title')"
          :labelBody="$t('view.body')"
          :hasTitle="true"
          :title_ar="row.title_ar"
          :title_en="row.title_en"
          :hasBody="true"
          :body_ar="row.body_ar"
          :body_en="row.body_en"
          v-on:formChange="handleFormChange"
        >
        </FormElements>
      </div>

      <div v-if="!pgLoading" class="col-4">
        <CardImage
          :labelImage="$t('view.image')"
          :image_base64="row.image_baes64"
          :image_preview="row.image_preview"
          v-on:imageChange="handleImageChange"
        >
        </CardImage>

        <CardSort :sort="row.sort" v-on:sortChange="handleSortChange">
        </CardSort>

        <CardStatus :status="row.status" v-on:statusChange="handleStatusChange">
        </CardStatus>
      </div>
    </div>

    <Buttons
      v-if="!pgLoading"
      :btnLoading="btnLoading"
      :btnCurrent="btnCurrent"
      v-on:submitClicked="handelSubmit"
      v-on:cancelClicked="handleCancel"
    >
    </Buttons>
  </div>
</template>

<script>
import iziToast from "izitoast";
import FormElements from "@/components/FormElements";
import CardImage from "@/components/CardImage";
import Loading from "@/components/Loading";
import Buttons from "@/components/Buttons";
import CardStatus from "@/components/CardStatus";
import CardSort from "@/components/CardSort";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default {
  name: "EditOrNew",
  components: {
    Loading,
    FormElements,
    CardImage,
    CardSort,
    CardStatus,
    Buttons,
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        id_token: "",
      },

      // row
      row: {
        title_ar: "",
        title_en: "",

        body_ar: "",
        body_en: "",

        image_base64: "",
        image_preview: "",

        transaction_type_id: "",
        sort: 0,
        status: 0,
      },

      msgCurrent: this.$route.params.id ? "Updated" : "Created",
      btnCurrent: this.$route.params.id ? "Update" : "Create",
      methodType: this.$route.params.id ? "PUT" : "POST",
      pgLoading: this.$route.params.id ? true : false,
      pgCurrent: this.$route.params.id ? "Edit" : "Add New",
      btnLoading: false,

      refs: "services",
    };
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    setCurrentPageTitle(this.$t("nav.services"), "bx bx-server");

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.title_ar = res.data.row.ar.title;
          this.row.title_en = res.data.row.en.title;

          this.row.body_ar = res.data.row.ar.body;
          this.row.body_en = res.data.row.en.body;

          this.row.image_preview = res.data.row.image
            ? res.data.row.image.url
            : "";
          this.row.image_base64 = res.data.row.image
            ? res.data.row.image.url
            : "";

          this.row.transaction_type_id = res.data.row.transaction_type_id;

          this.row.sort = res.data.row.sort;
          this.row.status = res.data.row.status;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.$route.params.id
          ? this.refs + "/" + this.$route.params.id
          : this.refs,
        method: this.methodType,
        data: {
          ar: {
            title: this.row.title_ar,
            body: this.row.body_ar,
          },

          en: {
            title: this.row.title_en,
            body: this.row.body_en,
          },

          image_base64: this.row.image_base64,

          transaction_type_id: this.row.transaction_type_id,
          sort: this.row.sort,
          status: this.row.status,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message:
              this.btnCurrent == "Update"
                ? "تم التعديل بنجاح"
                : "تم الإضافة بنجاح",
          });

          this.$router.push({ name: this.refs });
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("Are you sure?")) {
        this.$router.push({ name: this.refs });
      }
    },

    // handel Changes from child components
    handleFormChange(event) {
      this.row.title_ar = event.title_ar;
      this.row.title_en = event.title_en;

      this.row.body_ar = event.body_ar;
      this.row.body_en = event.body_en;
    },

    handleImageChange(event) {
      this.row.image_base64 = event.image_base64;
    },

    handleTransactionTypeChange(event) {
      this.row.transaction_type_id = event.transaction_type_id;
    },

    handleSortChange(event) {
      this.row.sort = event.sort;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },
  },
};
</script>
